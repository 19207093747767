<template>
    <div class="st-swiper">
        <div ref="stBox" class="st-swiper-box" :style="{ height: height }">
            <div
                class="st-swiper-item"
                :class="active == index ? 'active' : ''"
                v-for="(item, index) in imgList"
                :key="index"
                :style="calcSrc(item.src, item.text, index)"
            >
                <!-- <img class="swiper-img" :src="item.src" /> -->
            </div>
        </div>
        <button class="swiper-btn swiper-btn-left" @click="step('prev')">
            <i class="el-icon-arrow-left"></i>
        </button>
        <button class="swiper-btn swiper-btn-right" @click="step('next')">
            <i class="el-icon-arrow-right"></i>
        </button>
    </div>
</template>

<script>
export default {
    name: 'Swiper',
    data() {
        return {
            active: 0,
            t: 0,//节流变量
            tout: undefined,//loop定时
            imgList: [
                { src: require('../assets/home/banner1-1.png'), text: require('../assets/home/banner1-.jpg') },
                { src: require('../assets/home/banner2-2.png'), text: require('../assets/home/banner2-.jpg') },
                { src: require('../assets/home/banner3-3.png'), text: require('../assets/home/banner3-.jpg') },
            ],
            height: '240px',
        }
    },

    created() {
        this.loop();
        window.onresize = () => {
            this.calcH();
            this.calcSrc()
        }
    },
    mounted() {
        this.calcH();
    },
    methods: {
        // 计算图片并确定位置
        calcSrc: function (src, text, index) {
            let clientWidth = document.documentElement.clientWidth;
            return `background: url(${src}) no-repeat ${index == 0 ? 'center' : (clientWidth > 425 ? '20%' : '30%')} center/${clientWidth > 425 ? '40%' : '60%'},url(${text}) no-repeat center/cover`
        },
        // 动态设置轮播图高度
        calcH() {
            let calcHeight = Math.floor(document.body.clientWidth * 875 / 1920) - 10;
            this.height = `${calcHeight}px`;//动态计算轮播图的高度;
        },
        // 轮播循环
        loop() {
            let that = this;
            this.tout = setTimeout(() => {
                that.active += 1;
                if (that.active > that.imgList.length - 1) {
                    that.active = 0
                }
                clearTimeout(this.tout)
                that.loop()
            }, 5000);
        },
        // 轮播循环step
        step(direction) {
            // 点击重置时间
            clearTimeout(this.tout);
            // 来个节流，防止暴力点击
            let now = Date.now();
            if (now - this.t > 800) {
                if (direction == 'prev') {
                    this.active -= 1;
                    if (this.active == -1) {
                        this.active = this.imgList.length - 1
                    }
                }
                if (direction == 'next') {
                    this.active += 1;
                    if (this.active > this.imgList.length - 1) {
                        this.active = 0
                    }
                }
                this.t = now;
                //再次计时
                this.loop();
            }
        },
    },
}
</script>

<style lang='less' scoped>
.st-swiper {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.st-swiper-box {
    width: 300vw;
    min-height: 240px;
    position: relative;
    left: 0;
}

.st-swiper-item {
    width: 100vw;
    height: 100%;
    position: absolute;
    transition: 0.8s linear;
    opacity: 0;
    &.active {
        opacity: 1;
    }
}
.swiper-img {
    // width: 100%;
    height: 100%;
}
.swiper-btn {
    position: absolute;
    top: 50%;
    width: 3.6rem;
    height: 3.6rem;
    padding: 0;
    margin: 0;
    text-align: center;
    background: transparent;
    outline: none !important;
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: 1.6rem;
    color: #fff;
    transition: 0.3s;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    &:hover {
        color: #fff;
        background-color: #015aab;
        border: 1px solid #015aab;
    }
}
.swiper-btn-left {
    left: 16px;
}
.swiper-btn-right {
    right: 16px;
}
@media screen and (max-width: 425px) {
    .swiper-btn {
        width: 2.6rem;
        height: 2.6rem;
    }
}
</style>